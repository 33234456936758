import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Divider,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import {sendDataToApi, sendJsonToApi} from '../../../API/apiHelper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Google from '../../../assets/images/social-google.svg';
import GoogleLoginButton from "./GoogleLoginButton";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import { useDispatch } from "react-redux";
import {setAdmin, setUser} from '../../../redux/features/appStateSlice';


const FirebaseLogin = ({ ...rest }) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const routerHistory = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const params = {
                email: values.email,
                password: values.password
            };

            const check = await sendDataToApi('post','/api/login', params);
            if(check.data == 1) {
                const response = await sendDataToApi('post','/api/auth/login', params);
                if (response.data.access_token) {
                    Cookies.set('jwt', response.data.access_token, {expires: 1});
                    const userData = await sendDataToApi('get', '/api/user');
                    localStorage.setItem('user_id', userData.data.id);
                    localStorage.setItem('user_email', userData.data.email);
                    localStorage.setItem('user_name', userData.data.name);
                    const adminParams = {
                        email: userData.data.email,
                    };
                    const adminData = await sendDataToApi('post', '/api/user/admin', adminParams);
                    if (adminData.data === 1) {
                        dispatch(setAdmin());
                        Cookies.set('user_admin', adminData.data, {expires: 1});

                    }
                    const managerData = await sendDataToApi('post', '/api/user/ismanager', adminParams);
                    if (managerData.data === 1) {
                        Cookies.set('user_manager', managerData.data, {expires: 1});
                    }
                    const managerPubData = await sendDataToApi('post', '/api/user/ispubmanager', adminParams);
                    if (managerPubData.data === 1) {
                        Cookies.set('user_pub_manager', managerPubData.data, {expires: 1});
                    }
                    const bossOfAcManagersData = await sendDataToApi('post', '/api/user/isbossacmanager', adminParams);
                    if (bossOfAcManagersData.data === 1) {
                        Cookies.set('boss_ac_managers', bossOfAcManagersData.data, {expires: 1});
                    }
                    const bossOfPubManagersData = await sendDataToApi('post', '/api/user/isbosspubmanager', adminParams);
                    if (bossOfPubManagersData.data === 1) {
                        Cookies.set('boss_pub_managers', bossOfPubManagersData.data, {expires: 1});
                    }
                    routerHistory('/');
                    window.location.reload();
                }
            }else{
                toast.error(`User does not exist or blocked.`);
            }

        } catch (error) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <GoogleLoginButton />
                </Grid>
            </Grid>

            <Box alignItems="center" display="flex" mt={2}>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                <Typography color="textSecondary" variant="h5" sx={{ m: theme.spacing(2) }}>
                    OR
                </Typography>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
            </Box>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...rest}>
                        <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email Address / Username"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={values.email}
                            variant="outlined"
                        />

                        <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text">
                                    {' '}
                                    {errors.password}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box mt={2}>
                            <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                Log In
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseLogin;
